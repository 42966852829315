/* eslint-disable rulesdir/no-new-date */
import { addDate, addDateType } from '~/libs/addDate'
import { dateFormatter, dateFormatterJP } from '~/libs/dateFormatter'

export class DateUtil {
  // フィールド
  yyyy = 1970
  mm = 0
  dd = 1
  hh = 9
  mi = 0
  ss = 0
  ms = 0
  date!: Date

  // 現在日時
  constructor()
  // 文字列, Date型, タイムスタンプ型
  constructor(date: string | Date | number)
  // 年月日
  constructor(year: number, month: number, date: number)
  // 年月日時分秒
  constructor(year: number, month: number, date: number, hours: number, minutes: number, seconds: number)
  // 年月日時分秒ミリ秒
  constructor(year: number, month: number, date: number, hours: number, minutes: number, seconds: number, millSeconds: number)

  // コンストラクタの実態（引数によって振り分け）
  constructor(...array: (string|Date|number)[]) {
    switch (array.length) {
      // 引数が0個の場合
      case 0:
        this.date = new Date()
        this.yyyy = this.date.getFullYear()
        this.mm = this.date.getMonth()
        this.dd = this.date.getDate()
        this.hh = this.date.getHours()
        this.mi = this.date.getMinutes()
        this.ss = this.date.getSeconds()
        this.ms = this.date.getMilliseconds()
        break
      // 引数が0個の場合
      case 1:
        const type = (typeof array[0]).toString()
        switch (type) {
          case 'object':
            if (array[0] != null) {
              const date = array[0] as Date
              this.yyyy = date.getFullYear()
              this.mm = date.getMonth()
              this.dd = date.getDate()
              this.hh = date.getHours()
              this.mi = date.getMinutes()
              this.ss = date.getSeconds()
              this.ms = date.getMilliseconds()
            }
            this.setDate()
            break
          case 'string':
            // 年月日に分割
            const dateStr = array[0] as string
            const newDate = new Date(dateStr)
            if (newDate.toString() !== 'Invalid Date') {
              this.yyyy = newDate.getFullYear()
              this.mm = newDate.getMonth()
              this.dd = newDate.getDate()
              this.hh = newDate.getHours()
              this.mi = newDate.getMinutes()
              this.ss = newDate.getSeconds()
              this.ms = newDate.getMilliseconds()
              this.setDate()
            } else {
              const dateArray = dateStr.split(/[-\/\sT:.]/)
              if (dateArray.length == 3 || dateArray.length == 6 || dateArray.length == 7) {
                this.yyyy = Number(dateArray[0])
                this.mm = Number(dateArray[1]) - 1
                this.dd = Number(dateArray[2])
                if (dateArray.length == 6 || dateArray.length == 7) {
                  this.hh = Number(dateArray[3])
                  this.mi = Number(dateArray[4])
                  this.ss = Number(dateArray[5])
                  if (dateArray.length == 7) {
                    this.ms = Number(dateArray[6])
                  }
                }
                this.setDate()
              } else {
                // 分割エラー
                this.setErrorDate()
              }
            }
            break
          case 'number':
            // タイムスタンプからdateを作成
            const dateNumber = new Date(array[0] as number)
            this.yyyy = dateNumber.getFullYear()
            this.mm = dateNumber.getMonth()
            this.dd = dateNumber.getDate()
            this.hh = dateNumber.getHours()
            this.mi = dateNumber.getMinutes()
            this.ss = dateNumber.getSeconds()
            this.ms = dateNumber.getMilliseconds()
            this.setDate()
            break
          default:
            this.setErrorDate()
            break
        }
        break
      // 引数が3個の場合
      case 3:
        this.yyyy = array[0] as number
        this.mm = array[1] as number
        this.dd = array[2] as number
        this.setDate()
        break
      // 引数が6個の場合
      case 6:
        this.yyyy = array[0] as number
        this.mm = array[1] as number
        this.dd = array[2] as number
        this.hh = array[3] as number
        this.mi = array[4] as number
        this.ss = array[5] as number
        this.setDate()
        break
      // 引数が7個の場合
      case 7:
        this.yyyy = array[0] as number
        this.mm = array[1] as number
        this.dd = array[2] as number
        this.hh = array[3] as number
        this.mi = array[4] as number
        this.ss = array[5] as number
        this.ms = array[6] as number
        this.setDate()
        break
      // 引数が想定外の個数の場合
      default:
        this.setErrorDate()
        break
    }
    return
  }

  /**
   * 任意の年月日を加算したDateオブジェクトを返却する関数
   * 加算元のオブジェクトには影響しない
   */
  addDate = (target: addDateType, num: number): Date => {
    return addDate(this.date, target, num)
  }

  /**
   * 任意のセパレータ区切って文字列で返却する関数
   */
  dateFormatter = (format: string, separator: string): string => {
    return dateFormatter(this.date, format, separator)
  }

  /**
   * YYYY年MM月DD日(A)形式文字列を返却する関数
   */
  dateFormatterJP = (): string => {
    return dateFormatterJP(this.date)
  }

  /**
   * セットされた年月日時分秒ミリ秒を使ってdateを設定
   */
  private setDate = () => {
    this.date = new Date(
      this.yyyy,
      this.mm,
      this.dd,
      this.hh,
      this.mi,
      this.ss,
      this.ms,
    )
  }

  /**
   * 日付作成エラーの場合はInvalid Dateを設定
   */
  private setErrorDate = () => {
    this.yyyy = 0
    this.mm = 0
    this.dd = 0
    this.hh = 0
    this.mi = 0
    this.ss = 0
    this.ms = 0
    this.date = new Date('Invalid Date')
  }
}
