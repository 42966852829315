import { DateUtil } from '~/utils/date-util'

// Date型の日付加算処理
export function addDate (date: Date, target: addDateType, num: number) {
  const tempDate = new DateUtil(date).date
  let changedDate: Date = new DateUtil().date
  switch (target) {
    case addDateType.YEAR:
      changedDate = new DateUtil(tempDate.setFullYear(tempDate.getFullYear() + num)).date
      break
    case addDateType.MONTH:
      changedDate = new DateUtil(tempDate.setMonth(tempDate.getMonth() + num)).date
      break
    case addDateType.DATE:
      changedDate = new DateUtil(tempDate.setDate(tempDate.getDate() + num)).date
      break
  }
  return changedDate
}

export enum addDateType {
  YEAR = '年',
  MONTH = '月',
  DATE = '日'
}
