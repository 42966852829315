import { DateUtil } from '~/utils/date-util'
export function dateFormatter (input: Date | string, format: string, separator: string): string {
  if (input === undefined || input === null) {
    return ''
  }

  const inputDate: Date = new DateUtil(input.toString().replace(/-/g,"/").replace(/\.[0-9]*$/g, '')).date

  if (isNaN(inputDate.getTime())) {
    return input.toString()
  }

  const year = ('000' + inputDate.getFullYear().toString()).slice(-4)
  const month = ('0' + (inputDate.getMonth() + 1).toString()).slice(-2)
  const date = ('0' + inputDate.getDate().toString()).slice(-2)
  const hours = ('0' + inputDate.getHours().toString()).slice(-2)
  const minutes = ('0' + inputDate.getMinutes().toString()).slice(-2)
  const seconds = ('0' + inputDate.getSeconds().toString()).slice(-2)

  return makeDateStr(year, month, date, hours, minutes, seconds, format, separator)
}

export function dateFormatterNoZeroPadding (input: Date | string, format: string, separator: string): string {
  if (input === undefined || input === null) {
    return ''
  }

  const inputDate: Date = new DateUtil(input.toString().replace(/-/g,"/").replace(/\.[0-9]*$/g, '')).date
  if (isNaN(inputDate.getTime())) {
    return input.toString()
  }

  const year = inputDate.getFullYear().toString()
  const month = (inputDate.getMonth() + 1).toString()
  const date = inputDate.getDate().toString()
  const hours = inputDate.getHours().toString()
  const minutes = inputDate.getMinutes().toString()
  const seconds = inputDate.getSeconds().toString()

  return makeDateStr(year, month, date, hours, minutes, seconds, format, separator)
}

const makeDateStr = ( year: string,
                      month: string,
                      date: string,
                      hours: string,
                      minutes: string,
                      seconds: string,
                      format: string,
                      separator: string) => {
  let dateStr = ''
  switch (format) {
    case 'YYYYMMDDhhmmss':
      dateStr = year + separator + month + separator + date + ' ' + hours + ':' + minutes + ':' + seconds
      break
    case 'YYYYMMDDhhmm':
      dateStr = year + separator + month + separator + date + ' ' + hours + ':' + minutes
      break
    case 'YYYYMMDD':
      dateStr = year + separator + month + separator + date
      break
    case 'YYYYMM':
      dateStr = year + separator + month
      break
    case 'MMDD':
      dateStr = month + separator + date
      break
    case 'hhmmss':
      dateStr = hours + ':' + minutes + ':' + seconds
      break
    case 'hhmm':
      dateStr = hours + ':' + minutes
      break
  }
  return dateStr
}

export function dateFormatterJP (input: Date | string): string {
  if (input === undefined || input === null) {
    return ''
  }

  const inputDate: Date = new DateUtil(input.toString().replace(/-/g,"/").replace(/\.[0-9]*$/g, '')).date

  if (isNaN(inputDate.getTime())) {
    return input.toString()
  }

  const year = inputDate.getFullYear().toString()
  const month = (inputDate.getMonth() + 1).toString()
  const date = inputDate.getDate().toString()
  const week = inputDate.getDay()
  const days = ['日', '月', '火', '水', '木', '金', '土']

  return year + '年' + month + '月' + date + '日(' + days[week] + ')'
}

/**
  Formats the input date/string into a delivery period string.
  @param input - The date or string to format. If a string, it should be in YYYY/MM/DD format.
  @returns A string in the format [MM月DD日 HH:mm]
*/
export function dateFormatterDeliveryPeriod (input: Date | string): string {
  if (input === undefined || input === null) {
    return ''
  }

  const inputDate: Date = new DateUtil(input.toString().replace(/-/g,"/").replace(/\.[0-9]*$/g, '')).date

  if (isNaN(inputDate.getTime())) {
    return input.toString()
  }

  const month = (inputDate.getMonth() + 1).toString()
  const date = inputDate.getDate().toString()
  const hours = inputDate.getHours().toString()
  const minutes = ('0' + inputDate.getMinutes().toString()).slice(-2)

  return month + '月' + date + '日 ' + hours + ':' + minutes
}

export function dateFormatterNoDayOfWeekJP (input: Date | string): string {
  if (input === undefined || input === null) {
    return ''
  }

  const inputDate: Date = new DateUtil(input.toString().replace(/-/g,"/").replace(/\.[0-9]*$/g, '')).date

  if (isNaN(inputDate.getTime())) {
    return input.toString()
  }

  const year = inputDate.getFullYear().toString()
  const month = (inputDate.getMonth() + 1).toString()
  const date = inputDate.getDate().toString()
  const week = inputDate.getDay()

  return year + '年' + month + '月' + date + '日'
}
